<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    style="background-color: blue; border-radius: 30px !important"
  >
    <v-form v-model="valid">
      <v-card flat style="overflow: hidden">
        <v-card-title class="secondary py-1" style="height: 40px">
          <span class="dialog-title">{{ $t("hospital") }}</span>
          <v-spacer />
          <v-icon small color="primary" @click="$emit('close')"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <v-card-text color="primary" class="mt-5">
          <span
            >{{ $t("welcomeToHospitalRegistration") }}
            <span style="color: red">(required fields *)</span></span
          >
        </v-card-text>

        <v-row class="px-6">
          <v-row class="pl-6 pb-3 pt-4">
            <span style="font-size: 18px">Hospital details</span>
          </v-row>
          <v-col cols="12">
            <v-row dense cols="12" class="px-1 mb-2">
              <v-select
                v-model="hospital.medicalInstitutionId"
                :items="medicalInstitutions"
                item-text="institutionName"
                item-value="id"
                label="Medical Organization *"
                prepend-inner-icon="mdi-office-building mr-1"
                dense
                background-color="#E8E8E8"
                rounded
                outlined
                class="mb-3"
                style="border-radius: 12px !important; height: 40px"
                :rules="[(rule) => !!rule || '']"
              ></v-select>
            </v-row>
            <v-row dense cols="12" class="px-1 mb-5">
              <v-text-field
                v-model="hospital.hospitalName"
                label="Hospital Name *"
                prepend-inner-icon="mdi-hospital-building mr-1"
                dense
                background-color="#E8E8E8"
                rounded
                outlined
                style="border-radius: 12px !important; height: 40px"
                :rules="[(rule) => !!rule || '']"
              ></v-text-field>
              <v-text-field
                v-model="hospital.contactPerson"
                label="Contact Person *"
                prepend-inner-icon="mdi-account mr-1"
                dense
                background-color="#E8E8E8"
                rounded
                class="ml-3"
                outlined
                style="border-radius: 12px !important; height: 40px"
                :rules="[(rule) => !!rule || '']"
              ></v-text-field>
            </v-row>
            <v-row dense cols="12" class="px-1 mb-5">
              <v-col>
                <validation-provider name="Phone number" rules="required" v-slot="{ errors }">
                  <v-text-field
                    ref="phoneNumber"
                    v-model="hospital.contactPhone"
                    validate-on-blur
                    label="Phone number *"
                    prepend-inner-icon="mdi-cellphone"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    class="ml-n1"
                    style="border-radius: 12px !important; height: 40px"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col>
                <validation-provider ref="email" :name="$t('email')" rules="required|email" v-slot="{ errors }" >
                  <v-text-field
                    v-model="hospital.contactEmail"
                    label="Contact Email *"
                    prepend-inner-icon="mdi-email mr-1"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    class="ml-1 mr-n2"
                    style="border-radius: 12px !important; height: 40px"
                    :error-messages="emailError = errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row dense cols="12" class="px-1 mb-2">
              <v-text-field
                ref="address"
                @blur="locationDetails()"
                v-model="hospital.address"
                validate-on-blur
                label="Address, State, Postal Code, City, Country *"
                prepend-inner-icon="mdi-map-marker mr-1"
                dense
                background-color="#E8E8E8"
                rounded
                outlined
                class="mb-3"
                placeholder="Address, State, Postal Code, City, Country"
                style="border-radius: 12px !important; height: 40px"
                :rules="[(rule) => !!rule || '']"
              ></v-text-field>
            </v-row>
            <v-textarea
              v-model="hospital.description"
              label="Description *"
              prepend-inner-icon="mdi-card-text mr-1"
              dense
              background-color="#E8E8E8"
              rounded
              outlined
              style="border-radius: 12px !important"
              :rules="[(rule) => !!rule || '']"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-card-actions class="px-6">
          <Alert class="mt-4" v-if="showErrors" :title="title" :type="alertType"></Alert>
          <v-spacer />
          <div class="my-3">
            <v-btn class="table-create-button" @click="$emit('close')">
              <v-icon class="icon-cancel">mdi-window-close</v-icon>
              <span>{{ $t("cancel") }}</span>
            </v-btn>
            <v-btn
              class="table-create-button"
              :disabled="!valid"
              @click="createHospital"
            >
              <v-icon class="icon">mdi-plus</v-icon>
              <span>{{ $t("save") }}</span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import Alert from "@/views/Client/components/AlertDialog.vue";
import Hospital from "../../data/models/Hospital";
import { phoneNumberFormatter } from '../../utils/luxon-formater'

export default {
  components: {
    Alert
  },
  props: ["dialog", "type", "selectedHospital"],
  data() {
    return {
      showErrors: false,
      title: "Something went wrong",
      alertType: false,
      valid: false,
      object: {
        address: "",
        addressLatitude: 0,
        addressLongitude: 0,
        city: null,
        contactEmail: null,
        contactPerson: null,
        contactPhone: null,
        country: null,
        description: null,
        hospitalName: null,
        hospitalSpetializationId: null,
        id: null,
        medicalInstitutionData: null,
        medicalInstitutionId: null,
        state: null,
        zip: null,
      },
      address_info: {
        address: "",
        postal_code: "",
        city: "",
        state: "",
        country: "",
        lat: 0,
        lng: 0,
      },
      countries: [],
      medicalInstitutionId: -1,
      medicalInstitutions: [],
      hospitalName: "",
      contactPerson: "",
      contactEmail: "",
      description: "",
      contactPhone: "",
      phoneData: {
        phone: "",
        phoneCodeModel: "US 1",
      },
      emailError: false,
      validationErrors: []
    };
  },
  computed: {
    ...mapState({
      medicalInstitutionsFromRepo: (state) =>
        state.medicalInstitutions.medicalInstitutions,
    }),
    hospital() {
      if (this.type === "edit") {
        return this.selectedHospital;
      } else return this.object;
    },
  },
  async mounted() {
    await this.getMedicalInstitutions();
    this.hospital;
    if (this.type === "edit") {
      this.addressData();
    }
  },
  methods: {
    async addressData() {
      this.address_info = {
        address: this.hospital.address,
        postal_code: this.hospital.zip,
        city: this.hospital.city,
        state: this.hospital.state,
        country: this.hospital.country,
        lat: this.hospital.addressLatitude,
        lng: this.hospital.addressLongitude,
      };
    },
    async timeZone(lat, lng) {
      let object = {
        lat: lat,
        lng: lng,
      };
      const time_zone = await this.$store.dispatch(
        "googleAPI/getTimeZone",
        object
      );
      return time_zone;
    },
    async locationDetails() {
      if (this.hospital.address) {
        const geo_location = await this.$store.dispatch(
          "googleAPI/getGeoLocation",
          this.hospital.address
        );
        const address_components = geo_location.address_components;
        this.address_info.lat = geo_location.geometry.location.lat;
        this.address_info.lng = geo_location.geometry.location.lng;
        this.formattedAddress = geo_location.formatted_address;
        this.address_info.address = this.formattedAddress;
        address_components.forEach((ac) => {
          if (ac.types.includes("administrative_area_level_1")) {
            this.address_info.state = ac.long_name;
          }
          if (ac.types.includes("locality")) {
            this.address_info.city = ac.long_name;
          }
          if (ac.types.includes("country")) {
            this.address_info.country = ac.long_name;
          }
          if (ac.types.includes("postal_code")) {
            this.address_info.postal_code = ac.long_name;
          }
        });
        if (this.address_info.lat !== null) {
          const tz = await this.timeZone(
            this.address_info.lat,
            this.address_info.lng
          );
          if (tz) {
            this.timezone = tz.zoneName;
          }
        }
      }
    },
    checkValid () {
      this.validationErrors = []
      if (!phoneNumberFormatter(this.hospital.contactPhone)){
        this.validationErrors.push('phone')
      }
      if (this.emailError.length > 0) {
        this.validationErrors.push('email')
      }
      if(this.address_info.address === "") {
        this.validationErrors.push('address')
      }
      if (this.validationErrors.length > 0) {
        return false
      } else return true
    },
    async createHospital() {
      const valid = this.checkValid()
      if (!valid) {
        this.title = "Provided data is not valid: " + this.validationErrors.toString()
        this.alertType = 'error'
        this.showErrors = true
      }
      if (valid) {
        const body = new Hospital(
          this.hospital.medicalInstitutionId,
          this.hospital.hospitalName,
          this.hospital.description,
          this.hospital.contactPerson,
          this.hospital.contactPhone,
          this.hospital.contactEmail,
          this.address_info.address,
          this.address_info.postal_code,
          this.address_info.city,
          this.address_info.state,
          this.address_info.country,
          this.address_info.lat,
          this.address_info.lng
        );
        switch (this.type) {
          case "add":
            this.createhospital(body);
            break;
          case "edit":
            this.updatehospital(body);
            break;
          default:
            break;
        }
      }
    },
    async createhospital(body) {
      await this.$store.dispatch("hospitals/createHospital", body).then((res) => {
        this.$emit("false", res);
      });
    },
    async updatehospital(body) {
      body.id = this.hospital.id;
      await this.$store.dispatch("hospitals/editHospital", body).then((res) => {
        this.$emit("false", res);
      });
    },
    async getMedicalInstitutions() {
      await this.$store
        .dispatch("medicalInstitutions/getMedicalInstitutions")
        .then(() => {
          this.medicalInstitutions = this.medicalInstitutionsFromRepo;
          this.loading = false;
        });
    },
  },
};
</script>