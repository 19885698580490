module.exports = class Hospital {
    constructor(medicalInstitutionId, hospitalName, description, contactPerson, contactPhone, contactEmail, address,
        zip, city, state, country, addressLatitude, addressLongitude) {
        this.medicalInstitutionId = medicalInstitutionId
        this.hospitalName = hospitalName
        this.description = description
        this.contactPerson = contactPerson
        this.contactPhone = contactPhone
        this.contactEmail = contactEmail
        this.address = address
        this.zip = zip
        this.city = city
        this.state = state
        this.country = country
        this.addressLatitude = addressLatitude
        this.addressLongitude = addressLongitude
    }
};