var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticStyle:{"border-radius":"12px"},attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-start",attrs:{"cols":"8"}},[_c('h5',{staticClass:"d-flex align-left"},[_vm._v(_vm._s(_vm.$t("hospitals")))])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"table-search-button",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search-hospital'),"single-line":"","hide-details":"","filled":"","dense":"","background-color":"#E8E8E8","rounded":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"loading":_vm.loading,"search":_vm.search,"items":_vm.hospitals,"group-by":"medicalInstitutionData.institutionName","height":_vm.testHeight},on:{"click:row":_vm.hospitaldetails},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"pa-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(item.hospitalName))]),_vm._l((_vm.hospitalAdmins),function(item,i){return _c('v-row',{key:i,staticClass:"mx-4",staticStyle:{"backgroudncolor":"red"}},[_c('v-checkbox',{attrs:{"label":item.firstName},on:{"change":function($event){return _vm.assignAdminToHospital($event, item.id)}}})],1)})],2)],1)],1)],1)]}},{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
var items = ref.items;
return [_c('td',{staticClass:"canvas",staticStyle:{"vertical-align":"left !important","text-align":"left !important"},attrs:{"colspan":headers.length},on:{"click":toggle}},[(isOpen)?_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-chevron-up ")]):_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-chevron-down ")]),_c('span',{staticStyle:{"font-weight":"bold","margin-left":"2px","margin-right":"8px"}},[_vm._v(_vm._s(group))]),_c('v-chip',{staticClass:"mb-1 primary--text",attrs:{"pill":"","x-small":"","color":"white"}},[_vm._v(" "+_vm._s(items.length)+" ")])],1)]}},{key:"footer.prepend",fn:function(ref){return [(_vm.roleName === 'SYSTEM_ADMIN')?_c('v-btn',{staticClass:"table-create-button",attrs:{"label":"Test"},on:{"click":_vm.addHospital}},[_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t("addNewHospitalTooltip")))])],1):_vm._e()]}}],null,true)})],1),(_vm.dialog)?_c('AddHospitalDialog',{attrs:{"dialog":_vm.dialog,"type":_vm.dialogType,"selectedHospital":_vm.selectedHospital},on:{"false":_vm.hospitalUpdated,"close":_vm.closeDialog}}):_vm._e(),(_vm.responseStatus)?_c('ResponseDialog',{attrs:{"status":_vm.responseStatus,"title":_vm.responseTitle,"color":_vm.responseColor},on:{"update:status":function($event){_vm.responseStatus=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }