<template>
  <v-container fluid>
    <v-card flat style="border-radius: 12px">
      <v-card-title>
        <v-row>
          <v-col class="d-flex align-center justify-start" cols="8"
            ><h5 class="d-flex align-left">{{ $t("hospitals") }}</h5>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search-hospital')"
              single-line
              hide-details
              filled
              dense
              background-color="#E8E8E8"
              rounded
              class="table-search-button"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :search="search"
        :items="hospitals"
        group-by="medicalInstitutionData.institutionName"
        style="cursor: pointer"
        @click:row="hospitaldetails"
        :height="testHeight"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row class="pa-4">
              <v-col cols="12">
                <v-card flat>
                  <v-card-title>{{ item.hospitalName }}</v-card-title>
                  <v-row
                    class="mx-4"
                    v-for="(item, i) in hospitalAdmins"
                    :key="i"
                    style="backgroudncolor: red"
                  >
                    <v-checkbox
                      :label="item.firstName"
                      @change="assignAdminToHospital($event, item.id)"
                    ></v-checkbox>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
        <template
          v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }"
        >
          <td
            :colspan="headers.length"
            style="vertical-align: left !important; text-align: left !important"
            class="canvas"
            @click="toggle"
          >
            <v-icon v-if="isOpen" color="black"> mdi-chevron-up </v-icon>
            <v-icon v-else color="black"> mdi-chevron-down </v-icon>
            <span
              style="font-weight: bold; margin-left: 2px; margin-right: 8px"
              >{{ group }}</span
            >
            <v-chip class="mb-1 primary--text" pill x-small color="white">
              {{ items.length }}
            </v-chip>
          </td>
        </template>
        <template v-slot:[`footer.prepend`]="{}">
          <v-btn
            v-if="roleName === 'SYSTEM_ADMIN'"
            class="table-create-button"
            label="Test"
            @click="addHospital"
          >
            <v-icon class="icon">mdi-plus</v-icon>
            <span>{{ $t("addNewHospitalTooltip") }}</span>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <AddHospitalDialog
      v-if="dialog"
      :dialog="dialog"
      :type="dialogType"
      :selectedHospital="selectedHospital"
      @false="hospitalUpdated"
      @close="closeDialog"
    />
    <ResponseDialog v-if="responseStatus" :status.sync="responseStatus" :title="responseTitle" :color="responseColor"></ResponseDialog>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import AddHospitalDialog from "../dialogs/HospitalDialog.vue";
import ResponseDialog from "@/views/Client/components/Alert.vue";

export default {
  components: {
    AddHospitalDialog,
    ResponseDialog
  },
  data: () => ({
    responseStatus: false,
    responseTitle: "",
    responseColor: "",
    dialog: false,
    dialogType: null,
    loading: true,
    hospitals: [],
    hospitalId: -1,
    search: "",
    headerClass: "black_inner--text !important",
    hospitalAdmins: [],
    selectedHospital: null,
  }),
  computed: {
    ...mapState({
      hospitalsFromRepo: (state) => state.hospitals.hospitals,
    }),
    ...mapGetters({ roleName: "authentication/getRole" }),
    testHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return window.innerHeight - 320;
        case "sm":
          return window.innerHeight - 300;
        case "md":
          return window.innerHeight - 300;
        case "lg":
          return window.innerHeight - 280;
        case "xl":
          return window.innerHeight - 280;
        default:
          return 0;
      }
    },
    headers() {
      var headers = [];
      headers = [
        {
          text: this.$t("header-hospital-name"),
          value: "hospitalName",
          class: this.headerClass,
        },
        {
          text: this.$t("header-contact-person"),
          value: "contactPerson",
          class: this.headerClass,
        },
        {
          text: this.$t("header-contact-phone"),
          value: "contactPhone",
          class: this.headerClass,
        },
        {
          text: this.$t("header-contact-email"),
          value: "contactEmail",
          class: this.headerClass,
        },
      ];
      return headers;
    },
  },
  async mounted() {
    await this.getHospitals();
  },
  methods: {
    async getHospitals() {
      await this.$store.dispatch("hospitals/getHospitals").then(() => {
        this.hospitals = this.hospitalsFromRepo;
        this.loading = false;
      });
    },
    addHospital() {
      this.dialog = true;
      this.dialogType = "add";
    },
    hospitaldetails(item) {
      this.dialog = true;
      this.dialogType = "edit";
      this.selectedHospital = item;
    },
    closeDialog() {
      this.dialog = false;
      this.dialogType = null;
      this.selectedHospital = null;
    },
    hospitalUpdated (res) {
        if (res.resFlag) {
          this.responseColor = 'main_green'
          this.responseTitle = res.msg
          this.responseStatus = true
        } else {
          this.responseColor = 'main_red'
          this.responseTitle = res.msg
          this.responseStatus = true
        }
        this.dialog = false;
        this.dialogType = null;
        this.selectedHospital = null;
        this.getHospitals();
    },
  },
};
</script>